import { Form, Button } from 'react-bootstrap';
import { useState, useEffect, useContext } from 'react';
import UserContext from '../UserContext';
import { Navigate, useNavigate } from 'react-router-dom';

import Swal from 'sweetalert2';

import '../App.css';

export default function Register(){

		const {user} = useContext(UserContext);
		const navigate = useNavigate();
		const [firstName, setFirstName] = useState('');
		const [lastName, setLastName] = useState('');
		const [email, setEmail] = useState('')
		const [password, setPassword] = useState('');
		const [mobileNo, setMobileNo] = useState('');
		const [isActive, setIsActive] = useState(false);

		console.log(email);
		console.log(password);

		function registerUser(event){
			event.preventDefault();

			fetch(`${process.env.REACT_APP_API_URL}/users/checkEmail`, {
			    method: "POST",
			    headers:{
			        "Content-Type": "application/json"
			    },
			    body: JSON.stringify({
			        email: email
			    })
			})
			.then(res => res.json())
			.then(data =>{
			    console.log(data);

			    if(data){
			        Swal.fire({
			            title: "Duplicate email found",
			            icon: "error",
			            text: "Kindly provide another email to complete the registration."
			        })
			    }
			    else{

					fetch(`${process.env.REACT_APP_API_URL}/users/register`, {
						method: 'POST',
						headers: { 'Content-Type' : 'application/json'},
						body: JSON.stringify ({
							firstName: firstName,
							lastName: lastName,
							email: email,
							password: password,
							mobileNo: mobileNo
						})

					})
					.then(res => res.json())
					.then(data => {
						console.log(data);
						console.log("Check Details");
						console.log(data);

						if(data === true){

							Swal.fire({
								title: 'Great! Registration Successful',
                    			text: "Welcome to Laran 🍎 Store!",
                    			imageUrl: 'https://www.cnet.com/a/img/resize/0509f601d709967de67b9b1a44caecf28d3e82aa/hub/2022/10/15/4ee63290-986c-42c1-af30-1ab0e5ca6dd0/newemoji.png?auto=webp&width=768',
                    			imageWidth: 400,
                    			imageHeight: 200,
                    			imageAlt: 'Custom image'
                  })   
              }

        else{
          Swal.fire({
            title: 'Yay! Duplicate Email Found',
                    text: "Please Provide A Different Email.",
                    imageUrl: 'https://www.cambridge.org/elt/blog/wp-content/uploads/2019/07/Sad-Face-Emoji.png',
                    imageWidth: 400,
                    imageHeight: 200,
                    imageAlt: 'Custom image'
                })   
            }

					})
				
				}
			})

	}

		useEffect(() => {
			if(email !== '' && password !== ''  && firstName !== '' && lastName !== '' && mobileNo !== '' ){
			    setIsActive(true);
			}
			else{
				setIsActive(false);
			}
		}, [firstName, lastName, email, password, mobileNo])

		return(
			(user.id !== null)
			?
			<Navigate to = "/products" />
			:
			<div class="container" className="register">
			  <div class="form-container" className="register-form">
			    <h1 class="my-5" className="register-h1">Register</h1>
			    <form onSubmit={(event) => registerUser(event)}>
			      <div class="form-group">
			        <label for="firstName">First Name</label>
			        <input
			          type="text"
			          class="form-control"
			          placeholder="Enter First Name"
			          id="firstName"
			          value={firstName}
			          onChange={(event) => setFirstName(event.target.value)}
			          required
			        />
			      </div>
			      <div class="form-group">
			        <label for="lastName">Last Name</label>
			        <input
			          type="text"
			          class="form-control"
			          placeholder="Enter Last Name"
			          id="lastName"
			          value={lastName}
			          onChange={(event) => setLastName(event.target.value)}
			          required
			        />
			      </div>
			      <div class="form-group">
			        <label for="userEmail">Email address</label>
			        <input
			          type="email"
			          class="form-control"
			          placeholder="Enter email"
			          id="userEmail"
			          value={email}
			          onChange={(event) => setEmail(event.target.value)}
			          required
			        />
			        <small class="text-muted">
			          We'll never share your email with anyone else.
			        </small>
			      </div>
			      <div class="form-group">
			        <label for="mobileNo">Mobile Number</label>
			        <input
			          type="tel"
			          class="form-control"
			          placeholder="Enter Mobile Number"
			          id="mobileNo"
			          value={mobileNo}
			          onChange={(event) => setMobileNo(event.target.value)}
			          required
			        />
			      </div>
			      <div class="form-group">
			        <label for="password">Password</label>
			        <input
			          type="password"
			          class="form-control"
			          placeholder="Password"
			          id="password"
			          value={password}
			          onChange={(event) => setPassword(event.target.value)}
			          required
			        />
			      </div>
			      <button
			        class="btn btn-primary" className="register-button"
			        type="submit"
			        id="submitBtn"
			        disabled={!isActive}
			      >
			        Submit
			      </button>
			    </form>
			  </div>
			</div>

		)

}

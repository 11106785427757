import {Container,Nav,Navbar} from 'react-bootstrap';
import {Link, NavLink} from 'react-router-dom';
import UserContext from "../UserContext"
import {useState, Fragment, useContext} from 'react'
import '../App.css';


export default function AppNavBar() {

  const [userId] = useState(localStorage.getItem("userId"))
  const {user} = useContext(UserContext)

  return (
    <Navbar class="navbar" bg="light" expand="lg">
      <Container fluid>
        <Navbar.Brand as={Link} to="/">Laran 🍎 Store</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
            <Nav.Link as={NavLink} to="/">Home</Nav.Link>
            <Nav.Link as={NavLink} to="/products" >Products</Nav.Link>
            {(userId === null && user.id === null)? //s55 
              <Fragment>
                <Nav.Link as={NavLink} to="/register">Register</Nav.Link> 
                <Nav.Link as={NavLink} to="/login">Login</Nav.Link>
              </Fragment>
              :
                <Nav.Link as={NavLink} to="/logout" >Logout </Nav.Link>//s55
              }           
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

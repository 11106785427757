import { Fragment, useState, useContext } from 'react';
import { Navigate } from "react-router-dom";
import { useEffect } from "react";
import ProductCard from '../components/ProductCard';
import UserContext from '../UserContext';


export default function Products(){

	const { user } = useContext(UserContext);

	const [products, setProducts] = useState([]);
	
	useEffect(() =>{
		fetch(`${process.env.REACT_APP_API_URL}/products/active`)
		.then(res => res.json())
		.then(data => {
			console.log(data);
			setProducts(data.map(product  =>{
				return(
					<ProductCard key={product._id} productProp={product} />
				);
			}));
		})
	}, []);

	

	return(
		(user.isAdmin)
		?
			<Navigate to="/admin" />
		:	
		<>
			<h1>Products</h1>
			{products}
		</>
	)


}
import {useEffect, useState} from "react";
import { Button, Table, Modal, Form } from "react-bootstrap";
import {Navigate} from "react-router-dom";
import Swal from "sweetalert2";


export default function AdminDashboard(){

		const [userRole] = useState(localStorage.getItem("userRole"));
		const [allProducts, setAllProducts] = useState([]);
		const [productId, setProductId] = useState("");
		const [name, setName] = useState("");
		const [description, setDescription] = useState("");
		const [price, setPrice] = useState(0);
		const [stocks, setStocks] = useState(0);
		const [isActive, setIsActive] = useState(false);
		const [showAdd, setShowAdd] = useState(false);
		const [showEdit, setShowEdit] = useState(false);
		const openAdd = () => setShowAdd(true);
		const closeAdd = () => setShowAdd(false);
		const openEdit = (id) => {
			setProductId(id);

			fetch(`${ process.env.REACT_APP_API_URL }/products/${id}`)
			.then(res => res.json())
			.then(data => {

				console.log(data);

				setName(data.name);
				setDescription(data.description);
				setPrice(data.price);
				setStocks(data.stocks);

			});

			setShowEdit(true)

		};

		const closeEdit = () =>{

			setName('');
			setDescription('');
			setPrice(0);
			setStocks(0);

			setShowEdit(false);

		};

		const fetchData = () => {
			fetch(`${process.env.REACT_APP_API_URL}/products/all`)
			.then(res => res.json())
			.then(data => {
				console.log(data);

				setAllProducts(data.map(product => {
					return (
						<tr key={product._id}>
							<td>{product._id}</td>
							<td>{product.name}</td>
							<td>{product.description}</td>
							<td>{product.price}</td>
							<td>{product.stocks}</td>
							<td>{product.isActive ? "Active" : "Inactive"}</td>
							<td>
								{

									(product.isActive)
									?
										<Button variant="danger" size="sm" onClick={() => archive(product._id, product.name)}>Archive</Button>
									:
										<>
											<Button variant="success" size="sm" className="mx-1" onClick={() => unarchive(product._id, product.name)}>Unarchive</Button>
											<Button variant="secondary" size="sm" className="mx-1" onClick={() => openEdit(product._id)}>Edit</Button>
										</>

								}
							</td>
						</tr>
					)
				}));


			});


		}

		useEffect(()=>{
			fetchData();
		}, )

		const archive = (id, productName) => {
			console.log(id);
			console.log(productName);

			fetch(`${process.env.REACT_APP_API_URL}/products/${id}/archive`, {
				method: "PATCH",
				headers:{
					"Content-Type": "application/json",
					"Authorization": `Bearer ${localStorage.getItem("token")}`
				},
				body: JSON.stringify({
					isActive: false
				})
			})
			.then(res => res.json())
			.then(data => {
				console.log(data)

				if(data){
					Swal.fire({
 						title: 'Sweet! Archive Successful',
  						text: `${productName} is now inactive.`,
  						imageUrl: 'https://unsplash.it/400/200',
  						imageWidth: 400,
  						imageHeight: 200,
  						imageAlt: 'Custom image',
					})
					fetchData();

				}
				else{
					Swal.fire({
					title: "Archive unsuccessful",
					icon: "error",
					text: "Something went wrong. Please try again later!"
				});
				}
			})
		}

		const unarchive = (id, productName) => {
			console.log(id);
			console.log(productName);

			fetch(`${process.env.REACT_APP_API_URL}/products/${id}/archive`, {
				method: "PATCH",
				headers:{
					"Content-Type": "application/json",
					"Authorization": `Bearer ${localStorage.getItem("token")}`
				},
				body: JSON.stringify({
					isActive: true
				})
			})
			.then(res => res.json())
			.then(data => {
				console.log(data)

				if(data){
					Swal.fire({
						title: "Unarchive Successful",
						icon: "success",
						text: `${productName} is now active.`
					});
					fetchData();
				}
				else{
					Swal.fire({
						title: "Unarchive Unsuccessful",
						icon: "error",
						text: "Something went wrong. Please try again later!"
					});
				}

			})

		}

		const addProduct = (e) => {
				e.preventDefault();

				fetch(`${process.env.REACT_APP_API_URL}/products/create`,
					 {
					 	method: "POST",
					 	headers: {
							"Content-Type": "application/json",
							"Authorization": `Bearer ${localStorage.getItem('token')}`
						},
						body: JSON.stringify({
							name: name,
							description: description,
							price: price,
							stocks: stocks
						})

					})
					.then(res => res.json())
					.then(data => {
						console.log(data);

						if(data){
							Swal.fire({
							    title: "Product succesfully Added",
							    icon: "success",
							    text: `${name} is now added`
							});

							fetchData();
							closeAdd();
						}
						else{
							Swal.fire({
							    title: "Error!",
							    icon: "error",
							    text: `Something went wrong. Please try again later!`
							});
							closeAdd();
						}
					})

					setName('');
					setDescription('');
					setPrice(0);
					setStocks(0);
		}

		const editProduct = (e) =>{
				    e.preventDefault();

				     fetch(`${process.env.REACT_APP_API_URL}/products/${productId}/update`, {
				     	method: "PATCH",
				     	headers: {
				 			"Content-Type": "application/json",
				 			"Authorization": `Bearer ${localStorage.getItem('token')}`
				 		},
				 		body: JSON.stringify({
				 		    name: name,
				 		    description: description,
				 		    price: price,
				 		    stocks: stocks
				 		})
				     })
				     .then(res => res.json())
				     .then(data => {
				     	console.log(data);

				     	if(data){
				     		Swal.fire({
				     		    title: "Product succesfully Updated",
				     		    icon: "success",
				     		    text: `${name} is now updated`
				     		});

				     		fetchData();
				     		closeEdit();

				     	}
				     	else{
				     		Swal.fire({
				     		    title: "Error!",
				     		    icon: "error",
				     		    text: `Something went wrong. Please try again later!`
				     		});

				     		closeEdit();
				     	}

				     })

				     setName('');
				     setDescription('');
				     setPrice(0);
				     setStocks(0);
		}

		useEffect(() => {

			    if(name !== "" && description !== "" && price > 0 && stocks > 0){
			        setIsActive(true);
			    } else {
			        setIsActive(false);
			    }

			}, [name, description, price, stocks]);

			return(
				(userRole)
				?
				<>
					{/*Header for the admin dashboard and functionality for create product and show orders*/}
					<div className="mt-5 mb-3 text-center">
						<h1>Admin Dashboard</h1>
						{/*Adding a new product */}
						<Button variant="success" className="
						mx-2" onClick={openAdd}>Add Product</Button>
						{/*To view all the user orders*/}
						<Button variant="secondary" className="
						mx-2">Show Orders</Button>
					</div>
					{/*End of admin dashboard header*/}

					{/*For view all the products in the database.*/}
					<Table striped bordered hover>
				      <thead>
				        <tr>
				          <th>Product ID</th>
				          <th>Product Name</th>
				          <th>Description</th>
				          <th>Price</th>
				          <th>Stocks</th>
				          <th>Status</th>
				          <th>Actions</th>
				        </tr>
				      </thead>
				      <tbody>
			        	{allProducts}
				      </tbody>
				    </Table>
					{/*End of table for product viewing*/}

			    	{/*Modal for Adding a new product*/}
			        <Modal show={showAdd} fullscreen={true} onHide={closeAdd}>
			    		<Form onSubmit={e => addProduct(e)}>

			    			<Modal.Header closeButton>
			    				<Modal.Title>Add New Product</Modal.Title>
			    			</Modal.Header>

			    			<Modal.Body>
			    	        	<Form.Group controlId="name" className="mb-3">
			    	                <Form.Label>Product Name</Form.Label>
			    	                <Form.Control 
			    		                type="text" 
			    		                placeholder="Enter Product Name" 
			    		                value = {name}
			    		                onChange={e => setName(e.target.value)}
			    		                required
			    	                />
			    	            </Form.Group>

			    	            <Form.Group controlId="description" className="mb-3">
			    	                <Form.Label>Product Description</Form.Label>
			    	                <Form.Control
			    	                	as="textarea"
			    	                	rows={3}
			    		                placeholder="Enter Product Description" 
			    		                value = {description}
			    		                onChange={e => setDescription(e.target.value)}
			    		                required
			    	                />
			    	            </Form.Group>

			    	            <Form.Group controlId="price" className="mb-3">
			    	                <Form.Label>Product Price</Form.Label>
			    	                <Form.Control 
			    		                type="number" 
			    		                placeholder="Enter Product Price" 
			    		                value = {price}
			    		                onChange={e => setPrice(e.target.value)}
			    		                required
			    	                />
			    	            </Form.Group>

			    	            <Form.Group controlId="stocks" className="mb-3">
			    	                <Form.Label>Product Stocks</Form.Label>
			    	                <Form.Control 
			    		                type="number" 
			    		                placeholder="Enter Product Stocks" 
			    		                value = {stocks}
			    		                onChange={e => setStocks(e.target.value)}
			    		                required
			    	                />
			    	            </Form.Group>
			    			</Modal.Body>

			    			<Modal.Footer>
			    				{ isActive 
			    					? 
			    					<Button variant="primary" type="submit" id="submitBtn">
			    						Save
			    					</Button>
			    				    : 
			    				    <Button variant="danger" type="submit" id="submitBtn" disabled>
			    				    	Save
			    				    </Button>
			    				}
			    				<Button variant="secondary" onClick={closeAdd}>
			    					Close
			    				</Button>
			    			</Modal.Footer>

			    		</Form>	
			    	</Modal>
			    {/*End of modal for adding product*/}

		    	{/*Modal for Editing a product*/}
		        <Modal show={showEdit} fullscreen={true} onHide={closeEdit}>
		    		<Form onSubmit={e => editProduct(e)}>

		    			<Modal.Header closeButton>
		    				<Modal.Title>Edit a Product</Modal.Title>
		    			</Modal.Header>

		    			<Modal.Body>
		    	        	<Form.Group controlId="name" className="mb-3">
		    	                <Form.Label>Product Name</Form.Label>
		    	                <Form.Control 
		    		                type="text" 
		    		                placeholder="Enter Product Name" 
		    		                value = {name}
		    		                onChange={e => setName(e.target.value)}
		    		                required
		    	                />
		    	            </Form.Group>

		    	            <Form.Group controlId="description" className="mb-3">
		    	                <Form.Label>Product Description</Form.Label>
		    	                <Form.Control
		    	                	as="textarea"
		    	                	rows={3}
		    		                placeholder="Enter Product Description" 
		    		                value = {description}
		    		                onChange={e => setDescription(e.target.value)}
		    		                required
		    	                />
		    	            </Form.Group>

		    	            <Form.Group controlId="price" className="mb-3">
		    	                <Form.Label>Product Price</Form.Label>
		    	                <Form.Control 
		    		                type="number" 
		    		                placeholder="Enter Product Price" 
		    		                value = {price}
		    		                onChange={e => setPrice(e.target.value)}
		    		                required
		    	                />
		    	            </Form.Group>

		    	            <Form.Group controlId="stocks" className="mb-3">
		    	                <Form.Label>Product Stocks</Form.Label>
		    	                <Form.Control 
		    		                type="number" 
		    		                placeholder="Enter Product Stocks" 
		    		                value = {stocks}
		    		                onChange={e => setStocks(e.target.value)}
		    		                required
		    	                />
		    	            </Form.Group>
		    			</Modal.Body>

		    			<Modal.Footer>
		    				{ isActive 
		    					? 
		    					<Button variant="primary" type="submit" id="submitBtn">
		    						Save
		    					</Button>
		    				    : 
		    				    <Button variant="danger" type="submit" id="submitBtn" disabled>
		    				    	Save
		    				    </Button>
		    				}
		    				<Button variant="secondary" onClick={closeEdit}>
		    					Close
		    				</Button>
		    			</Modal.Footer>

		    		</Form>	
		    	</Modal>
		    	{/*End of modal for editing a product*/}
				</>
				:
				<Navigate to="/products" />
			)
	}
import { useState, useEffect, useContext } from "react";
import {Container, Card, Button, Row, Col} from "react-bootstrap";
import { useParams, Link } from "react-router-dom";
import Swal from "sweetalert2";
import { Form } from "react-bootstrap";
import UserContext from "../UserContext";


export default function ProductView(){

	const { user } = useContext(UserContext);
	const { productId } = useParams();
	const [name, setName] = useState('');
	const [description, setDescription] = useState('');
	const [price, setPrice] = useState(0);
	const [quantity, setQuantity] = useState(0)
	const [stocks, setStocks] = useState(0)

	useEffect(()=>{
		console.log(productId);

		fetch(`${ process.env.REACT_APP_API_URL }/products/${productId}`)
		.then(res => res.json())
		.then(data => {

			console.log(data);

			setName(data.name);
			setDescription(data.description);
			setPrice(data.price);
			setStocks(data.stocks);

		});

	}, [productId])

	const order = (productId, quantity) => {

		fetch(`${ process.env.REACT_APP_API_URL }/users/order`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${ localStorage.getItem('token') }`
			},
			body: JSON.stringify({
				productId: productId,
				quantity: quantity
			})
		})
		.then(res => res.json())
		.then(data => {

			console.log("Product data: ")
			console.log(data);

			if(data){
				Swal.fire({
					title: 'Nice! Order Successfull',
                	text: "You have successfully ordered this product.",
                	imageUrl: 'https://m.media-amazon.com/images/I/51+Fdu++PcL._AC_SX679_.jpg',
                	imageWidth: 400,
                	imageHeight: 200,
                	imageAlt: 'Custom image'
                })   
            }

			else{
				Swal.fire({
          title: 'Oppss! Something went wrong',
                  text: "No stocks available.",
                  imageUrl: 'https://www.mojix.com/wp-content/uploads/2016/12/stockout.jpg',
                  imageWidth: 400,
                  imageHeight: 200,
                  imageAlt: 'Custom image'
                })   
            }

		});
	}

	return (
		<Container className="mt-5">
			<Row>
				<Col lg={{ span: 6, offset: 3 }}>
					<Card>
						<Card.Body className="text-center">
							<Card.Title>{name}</Card.Title>
							<Card.Subtitle>Description:</Card.Subtitle>
							<Card.Text>{description}</Card.Text>
							<Card.Subtitle>Stocks:</Card.Subtitle>
							<Card.Text>{stocks}</Card.Text>
							<Card.Subtitle>Price:</Card.Subtitle>
							<Card.Text>PhP {price}</Card.Text>
							{user.id !== null ? (
								<div>
									<Form.Control
										type="number"
										min="1"
										placeholder="Enter quantity"
										value={quantity}
										onChange={e => setQuantity(e.target.value)}
									/>
									<Button
										variant="primary"
										size="lg"
										onClick={() => order(productId, quantity)}
									>
										Order
									</Button>
								</div>
							) : (
								<Button
									as={Link}
									to="/login"
									variant="success"
									size="lg"
								>
									Login to Order
								</Button>
							)}
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Container>
	)

}

import {Fragment} from 'react';
import Banner from '../components/Banner'
import '../App.css';



export default function Home(){

	const data = {
		title: "Laptops	💻 Phones 📱 Electronics 🖥️ ",
		destination: "/"
	}



		return (
			<Fragment>
				<div className="homeBg">
				<Banner data={data} />
				</div>
			</Fragment>
			)


}

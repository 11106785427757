import { Form, Button } from 'react-bootstrap';
import { useState, useEffect, useContext } from 'react';
import {Navigate} from 'react-router-dom';
import Swal from 'sweetalert2';
import UserContext from '../UserContext'
import '../App.css';


export default function Login() {

    const {user, setUser} = useContext(UserContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isActive, setIsActive] = useState(true);

    console.log(user);
    console.log(user.id);

    function authenticate(e){
        e.preventDefault();

        fetch(`${process.env.REACT_APP_API_URL}/users/login`, {
            method: 'POST',
            headers: { 'Content-Type' : 'application/json'},
            body: JSON.stringify({
                email: email,
                password: password
            })
        })
        .then(res => res.json())
        .then(data => {
            console.log(data);
            console.log("Check accessToken");
            console.log(data.access);

            if(typeof data.access !== "undefined"){
                localStorage.setItem('token' , data.access);
                retrieveUserDetails(data.access);


            Swal.fire({
                title: 'Sweet! Login Successful',
                text: "Welcome to Zuitt!",
                imageUrl: 'https://www.incimages.com/uploaded_files/image/1920x1080/getty_459097117_106961.jpg',
                imageWidth: 400,
                imageHeight: 200,
                imageAlt: 'Custom image'
                })   
            }




            else{
                Swal.fire({
                    title: 'Oh No! Authentication failed',
                    text: "Check your login details and try again.",
                    imageUrl: 'https://www.blazemeter.com/sites/default/files/image/2022-08/blz-blog-api-fail.jpg',
                    imageWidth: 400,
                    imageHeight: 200,
                    imageAlt: 'Custom image'
                })   
            }
        }) 

        setEmail(''); 
    }

    const retrieveUserDetails = (token) => {
        fetch(`${process.env.REACT_APP_API_URL}/users/details`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(data => {
            console.log("retrieveUserDetails: ")
            console.log(data);

            setUser({
                id: data._id,
                isAdmin: data.isAdmin
            })

            localStorage.setItem("userId", data._id);
            localStorage.setItem("userRole", data.isAdmin);

            console.log("Login: ");
            console.log(user)
        })
    }

    useEffect(() => {
            if(email !== '' && password !== ''){
                setIsActive(true);
            }else{
                setIsActive(false);
            }

        }, [email, password]);

    
        return (
            (user.id !== null)
            ? (<Navigate to="/products" />):(
            <Form onSubmit = {(e) => authenticate(e)}>
            <h3> Login </h3>
                <Form.Group controlId="userEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control 
                        type="email" 
                        placeholder="Enter email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </Form.Group>

                <Form.Group controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control 
                        type="password" 
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </Form.Group>

                 { isActive ?
                    <Button variant="primary" type="submit" id="submitBtn">
                        Submit
                    </Button>
                    :
                    <Button variant="danger" type="submit" id="submitBtn" disabled>
                        Submit
                    </Button>
                }

            </Form>
            
        )
        )

    }
import { Card, Button } from 'react-bootstrap';
import {Link} from "react-router-dom";



import '../App.css';

export default function ProductCard({productProp}) {


  const {_id, name, description, price, stocks} = productProp

  

  return (
    
          <Card>
               <Card.Body>
                   <Card.Title>{name}</Card.Title>
                   <Card.Subtitle>Description:</Card.Subtitle>
                   <Card.Text>{description}</Card.Text>
                   <Card.Subtitle>Stocks:</Card.Subtitle>
                   <Card.Text>{stocks}</Card.Text>
                   <Card.Subtitle>Price:</Card.Subtitle>
                   <Card.Text>{price}</Card.Text>
                   <Button as={Link} to={`/products/${_id}`}>Order</Button>
               </Card.Body>
           </Card>
       ) 
  

}
import './App.css';
import { Container } from 'react-bootstrap';
import AppNavbar from './components/AppNavbar';
import Home from './pages/Home';
import Register from './pages/Register';
import Login from './pages/Login';
import Logout from './pages/Logout';
import AdminDashboard from './pages/AdminDashboard'
import Products from './pages/Products'
import { BrowserRouter as Router } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import { UserProvider } from './UserContext';
import { useState } from 'react';
import ProductView from './pages/ProductView';



function App() {

  const [user, setUser] = useState({
    id: localStorage.getItem('id')
  })

  const unsetUser = () => {
    localStorage.clear();
  }


  return (
    <UserProvider value = {{user, setUser, unsetUser}}>
      <Router>
        <Container fluid>
          <AppNavbar />
          <Routes>
           <Route exact path="/" element={<Home />} />
            <Route exact path="/admin" element={<AdminDashboard />} />
            <Route exact path="/products" element={<Products />} />
            <Route exact path="/register" element={<Register />} />
           <Route exact path="/login" element={<Login />} />
           <Route exact path="/logout" element={<Logout />} /> 
           <Route exact path="/products/:productId" element={<ProductView />} />
          </Routes>
        </Container>
      </Router>
    </ UserProvider>
    );
}



export default App;